// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-jsx": () => import("./../src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presentations-jsx": () => import("./../src/pages/presentations.jsx" /* webpackChunkName: "component---src-pages-presentations-jsx" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-response-js": () => import("./../src/pages/response.js" /* webpackChunkName: "component---src-pages-response-js" */),
  "component---src-templates-archive-post-jsx": () => import("./../src/templates/archive-post.jsx" /* webpackChunkName: "component---src-templates-archive-post-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-pres-post-js": () => import("./../src/templates/pres-post.js" /* webpackChunkName: "component---src-templates-pres-post-js" */)
}

